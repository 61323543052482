/**
 * SuperBox
 * The lightbox reimagined. Fully responsive HTML5 image galleries.
 *
 * Latest version: https://github.com/seyDoggy/superbox
 * Original version: https://github.com/toddmotto/superbox
 *
 * License <https://github.com/seyDoggy/superbox/blob/master/LICENSE.txt>
 */
;(function ($, undefined) {
  'use strict';

  var pluginName = 'SuperBox',
    pluginVersion = '3.1.1';

  $.fn.SuperBox = function (options) {

    /*
     * OPTIONS
     */
    var defaults = $.extend({
      background: null,
      border: null,
      height: 512,
      view: 'landscape',
      xColor: null,
      xShadow: 'none',
      nav: false
    }, options);

    /*
     * DECLARATIONS
     */
    var sbIsIconShown = false,
      sbIsNavReady = false,
      sbShow = $('<div class="superbox-show">'),
      sbImg = $('<a href="" data-fancybox class="superbox-current-link"><img src="" class="superbox-current-img"></a>'),
      
      sbItemWrapper = $('<div class="item-wrapper">'),
      sbTitle = $('<h3 class="item-title"><a>'),
      sbDesc = $('<div class="item-description">'),
      sbMaterial = $('<div class="item-material"><span class="title">Материал:</span> <span class="value"></span></div>'),
      sbCover = $('<div class="item-cover"><span class="title">Отделка:</span> <span class="value"></span></div>'),
      sbItems = $('<div class="item-items"><span class="title">Количество изделий:</span> <span class="value"></span></div>'),
      sbItems = $('<div class="item-items"><span class="title">Количество изделий:</span> <span class="value"></span></div>'),
      sbPrices = $('<div class="item-prices"><span class="title">Цена:</span> <span class="value"></span></div>'),
      sbButtons = $('<div class="item-buttons"><a href="/order" id="button-order" class="button" rel="nofollow">Заказать похожую мебель</a><a href="/solution" id="button-question" class="button button-transparent" rel="nofollow">Задать вопрос</a></div>'),

      sbClose = $('<a href="#" class="superbox-close"></a>'),
      sbFloat = $('<div class="superbox-float">'),
      sbList = this.find('>div.item'),
      sbList8 = this.find('>div.item:nth-child(8n)'),
      sbList7 = this.find('>div.item:nth-child(7n)'),
      sbList6 = this.find('>div.item:nth-child(6n)'),
      sbList5 = this.find('>div.item:nth-child(5n)'),
      sbList4 = this.find('>div.item:nth-child(4n)'),
      sbList3 = this.find('>div.item:nth-child(3n)'),
      sbList2 = this.find('>div.item:nth-child(2n)'),
      sbWrapper = this;

    if (defaults.nav) {
      var sbPrev = $('<a href="#" class="superbox-prev" rel="nofollow"><i class="icon-circle-arrow-left"></i></a>'),
        sbNext = $('<a href="#" class="superbox-next" rel="nofollow"><i class="icon-circle-arrow-right"></i></a>');
    }

    /*
     * METHODS
     */
    /**
     * setSuperboxLayout
     *
     * Removes previously set classes,
     * Add classes based on parent width,
     * Set .superbox.show width based number of columns
     */
    var setSuperboxLayout = function (num) {
      var setColumns = function (num) {
        var lastItem,
          columnClass = 'superbox-' + num,
          classArray = ['superbox-last', 'superbox-8', 'superbox-7', 'superbox-6', 'superbox-5', 'superbox-4', 'superbox-3', 'superbox-2'];
        if (num === 8) {
          lastItem = sbList8;
        }
        else if (num === 7) {
          lastItem = sbList7;
        }
        else if (num === 6) {
          lastItem = sbList6;
        }
        else if (num === 5) {
          lastItem = sbList5;
        }
        else if (num === 4) {
          lastItem = sbList4;
        }
        else if (num === 3) {
          lastItem = sbList3;
        }
        else if (num === 2) {
          lastItem = sbList2;
        }
        /*
         * remove classes
         */
        sbList = sbList.filter('.portfolio-item');
        for (var i = classArray.length - 1; i >= 0; i--) {
          sbList.removeClass(classArray[i]);
        }
        /*
         * add classes
         */
        sbList.addClass(columnClass);
        lastItem.add(sbList.last()).addClass('superbox-last');
        /*
         * set superbox-show width
         */
        if (sbWrapper.find('.superbox-show').outerWidth(true) != sbList.width() * num) {
          sbWrapper.find('.superbox-show').outerWidth(sbList.width() * num);
        }
      };

      if (sbWrapper.width() > 1920) {
        setColumns(6);
      }
      else if (sbWrapper.width() > 1600) {
        setColumns(6);
      }
      else if (sbWrapper.width() > 1440) {
        setColumns(5);
      }
      else if (sbWrapper.width() > 1024) {
        setColumns(4);
      }
      else if (sbWrapper.width() > 1024) {
        setColumns(4);
      }
      else if (sbWrapper.width() > 640) {
        setColumns(3);
      }
      else if (sbWrapper.width() > 320) {
        setColumns(2);
      }
      else {
        setColumns(2);
      }
    };

    /**
     * setSuperBoxHeight
     *
     * Set superbox-show outer height based on default height,
     * based on viewport height,
     * based on standard 2:3 ratio,
     * based on default orientation.
     */
    var setSuperBoxHeight = (function () {
      var thisWidth = sbWrapper.find('.superbox-show').outerWidth(true),
        thisHeight = defaults.height + (16 * 3), /* 1.5em padding */
        newHeight = thisHeight,
        thisWindow = $(window).height() * 0.90,
        thisView = defaults.view,
        thisRatio = 0.6667;

      if (newHeight > thisWindow) {
        newHeight = thisWindow;
      }
      if ((thisView === 'landscape') && (thisWidth < newHeight / thisRatio)) {
        newHeight = thisWidth * thisRatio;
      }
      if ((thisView === 'portrait') && (thisWidth < newHeight * thisRatio)) {
        newHeight = thisWidth / thisRatio;
      }
      if ((thisView === 'square') && (thisWidth < newHeight)) {
        newHeight = thisWidth;
      }
      sbWrapper.find('.superbox-show').outerHeight(newHeight);

      if ($(window).width() < 992) {
        sbWrapper.find('.superbox-show').height('auto');
      }
    });

    /**
     * createSuperboxShow
     *
     * Dynamically create superbox-show and insert it after superbox-last,
     * apply data-img of the thumbnail to the source of the full image,
     * preload previous and next full size image data into DOM,
     * open the superbox-show,
     * fade in and out of each image,
     * animate image to top of clicked row,
     * close superbox-show when X is clicked,
     * close superbox-show when open image is clicked
     */
    var createSuperboxShow = function (elem) {

      /*
       * DECLARATIONS (createSuperboxShow)
       */
      removeActiveClasses();
      elem.addClass('active');

      var noSuperbox = !sbWrapper.find('.superbox-show').length,
        isOpen = elem.hasClass('superbox-O'),
        notLast = !elem.hasClass('superbox-last'),
        notInRow = !elem.nextAll('.superbox-last:first').next('.superbox-show').length,
        showNotNext = !elem.next('.superbox-show').length,
        /*
         * METHODS (createSuperboxShow)
         */
        openSuperBoxShow = function (type) {
          if (type === 'A') {
            sbShow.append(sbImg)
              .append(sbItemWrapper)
              .append(sbClose).append(sbPrev).append(sbNext).insertAfter(elem.nextAll('.superbox-last:first'));
            sbItemWrapper
              .append(sbTitle)
              .append(sbDesc)
              .append(sbMaterial)
              .append(sbCover)
              .append(sbItems)
              .append(sbPrices)
              .append(sbButtons);
          }
          else {
            sbShow.append(sbImg)
              .append(sbItemWrapper)
              .append(sbClose).insertAfter(elem);
            sbItemWrapper
              .append(sbTitle)
              .append(sbDesc)
              .append(sbMaterial)
              .append(sbCover)
              .append(sbItems)
              .append(sbPrices)
              .append(sbButtons);
          }
          setSuperBoxHeight();
          setSuperboxLayout();
          setImageData();
          setWrapperData();
          sbWrapper.find('.superbox-show').slideDown(function () {
            $(this).css('display', 'flex');
            //moveToTop();
            setOpenClass(true);
            revealImage(true);
            if (sbIsNavReady === false) {
              sbWrapper.find('.superbox-prev,.superbox-next').unbind('click').on('click', function (event) {
                navigation($(this), event);
                sbIsNavReady = true;
              });
              /*
               * Keyboard navigation
               */
              // $(document.documentElement).keyup(function (event) {
              //   if (isScrolledIntoView() === true) {
              //     navigation($(this), event);
              //     sbIsNavReady = true;
              //   }
              // });
            }
          });
        },
        setImageData = function () {
          sbWrapper.find('.superbox-show .superbox-current-link').attr('href', elem.find('img').data('img'));
          sbWrapper.find('.superbox-show img.superbox-current-img').attr('src', elem.find('img').data('img'));
          preloadImageData();
        },

        setWrapperData = function () {
          var itemWrapper = sbWrapper.find('.superbox-show .item-wrapper');
          var img = elem.find('img');

          //console.log(img.data('items'));

          var itemNid = img.data('nid'),
              itemUrl = img.data('url'),
              itemTitle = img.data('title'),
              itemBody = img.data('body'),
              itemMaterial = img.data('material'),
              itemCover = img.data('cover'),
              itemItems = (img.data('items') !== '') ? img.data('items') : 1,
              itemPrices = img.data('prices'),
              itemType = img.data('type');

          itemWrapper.find('.item-description').text(itemBody);

          console.log(itemType);

          if (itemType === 'product') {
            itemWrapper.find('.item-title a').text(itemTitle).attr('href', itemUrl);
            itemWrapper.find('.item-material span.value').text(itemMaterial);
            itemWrapper.find('.item-cover span.value').text(itemCover);
            itemWrapper.find('.item-items span.value').text(itemItems);
            itemWrapper.find('.item-prices span.value').text(itemPrices);

            sbMaterial.removeClass('hidden').attr('hidden', false);
            sbCover.removeClass('hidden').attr('hidden', false);
            sbItems.removeClass('hidden').attr('hidden', false);
            sbPrices.removeClass('hidden').attr('hidden', false);
            //sbButtons.removeClass('hidden').attr('hidden', false);
          } else {
            itemWrapper.find('.item-title a').text(itemTitle).removeAttr('href');
            sbMaterial.addClass('hidden').attr('hidden', true);
            sbCover.addClass('hidden').attr('hidden', true);
            sbItems.addClass('hidden').attr('hidden', true);
            sbPrices.addClass('hidden').attr('hidden', true);
            //sbButtons.addClass('hidden').attr('hidden', true);
          }
        },

        preloadImageData = function () {
          var imgPrev = new Image(),
            imgNext = new Image();
          imgPrev.src = elem.prev('.superbox-list').find('img').data('img');
          imgNext.src = elem.nextAll('.superbox-list:first').find('img').data('img');
        },
        moveToTop = function () {
          $('html, body').animate({
            scrollTop: sbWrapper.find('.superbox-show').offset().top - elem.width()
          }, 'medium');
        },
        isScrolledIntoView = function () {
          var docViewTop = $(window).scrollTop();
          var docViewBottom = docViewTop + $(window).height();

          var elemTop = sbWrapper.find('.superbox-show').offset().top;
          var elemBottom = elemTop + sbWrapper.find('.superbox-show').height();

          return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
        },
        setOpenClass = function (bool) {
          if (bool === true) {
            sbList.removeClass('superbox-O');
            elem.addClass('superbox-O');
          }
          else {
            sbList.removeClass('superbox-O');
          }
        },
        revealImage = function (bool) {
          if (bool === true) {
            sbWrapper.find('.superbox-show img.superbox-current-img').animate({opacity: 1}, 750);
            sbWrapper.find('.superbox-show .item-wrapper').animate({opacity: 1}, 750);
            if (sbIsIconShown === false) {
              revealIcons(true);
            }
          }
          else {
            sbWrapper.find('.superbox-show img.superbox-current-img').animate({opacity: 0}, 100, function () {
              setImageData();
            });
            sbWrapper.find('.superbox-show .item-wrapper').animate({opacity: 0}, 100, function () {
              setWrapperData();
            });
          }
        },
        revealIcons = function (bool) {
          if (bool === true) {
            sbIsIconShown = true;
            sbWrapper.find('.superbox-close, .superbox-prev, .superbox-next').animate({opacity: 0.7}, 750);
          }
          else {
            sbIsIconShown = false;
            sbWrapper.find('.superbox-close, .superbox-prev, .superbox-next').animate({opacity: 0}, 100);
          }
        },
        navigation = function (select, event) {
          event.preventDefault();
          var direction = null,
            selector = null;
          if (event.keyCode == 37 || select.hasClass('superbox-prev')) {
            /*
             * go left
             */
            direction = 'prev';
            selector = '.superbox-list';
          }
          else if (event.keyCode == 39 || select.hasClass('superbox-next')) {
            /*
             * go right
             */
            direction = 'nextAll';
            selector = '.superbox-list:first';
          }
          if (direction !== null) {
            sbWrapper.find('.superbox-O')[direction](selector).click();
          }
        },
        quickSwap = function () {
          revealImage(false);
          revealImage(true);
          setOpenClass(true);
        },
        closeSuperBoxShow = function () {
          var closeUp = function () {
            revealImage(false);
            revealIcons(false);
            sbWrapper.find('.superbox-show').slideUp(function () {
              $(this).remove();
              setOpenClass(false);
              sbIsNavReady = false;
            });
            removeActiveClasses();
          };
          sbWrapper.find('.superbox-close').unbind('click').on('click', function (event) {
            event.preventDefault();
            closeUp();
          });
          if (isOpen === true) {
            closeUp();
          }
        };

      /*
       * IMPLEMENTATION (createSuperboxShow)
       */
      if (isOpen === false) {
        if (notLast === true && notInRow === true) {
          if (noSuperbox === true) {
            openSuperBoxShow('A');
          }
          else {
            revealImage(false);
            revealIcons(false);
            sbWrapper.find('.superbox-show').slideUp(function () {
              openSuperBoxShow('A');
            });
          }
        }
        else if (notLast === false && showNotNext === true) {
          if (noSuperbox === true) {
            openSuperBoxShow('B');
          }
          else {
            revealImage(false);
            revealIcons(false);
            sbWrapper.find('.superbox-show').slideUp(function () {
              openSuperBoxShow('B');
            });
          }
        }
        else {
          quickSwap();
        }
      }
      closeSuperBoxShow();
    };

    /**
     * keepShowAfterLast
     *
     * Move superbox-show to after superbox-last when window is resized
     */
    var keepShowAfterLast = function () {
      $(window).resize(function () {
        if (sbWrapper.find('.superbox-O').hasClass('superbox-last')) {
          sbWrapper.find('.superbox-show').insertAfter(sbWrapper.find('.superbox-O'));
        }
        else {
          sbWrapper.find('.superbox-show').insertAfter(sbWrapper.find('.superbox-O').nextAll('.superbox-last:first'));
        }
      });
    };

    /**
     * useDefaults
     *
     * Make us of and apply user settings
     */
    var useDefaults = function () {
      if (defaults.background !== null) {
        sbWrapper.find('.superbox-show').css('background-color', defaults.background);
      }
      if (defaults.border !== null) {
        sbWrapper.find('.superbox-show img.superbox-current-img').css('border-color', defaults.border);
      }
      if (defaults.xColor !== null) {
        sbWrapper.find('.superbox-close, .superbox-prev, .superbox-next').css('color', defaults.xColor);
      }
      if (defaults.xShadow == 'emboss') {
        sbWrapper.find('.superbox-close, .superbox-prev, .superbox-next').css('text-shadow', '0 1px 0 rgba(0,0,0,0.6), 0 -1px 0 rgba(250,250,250,0.2)');
      }
      else if (defaults.xShadow == 'embed') {
        sbWrapper.find('.superbox-close, .superbox-prev, .superbox-next').css('text-shadow', '0 -1px 0 rgba(0,0,0,0.4), 0 1px 0 rgba(250,250,250,0.5)');
      }
    };

    /**
     * Remove 'active' class from superbox items
     */
    var removeActiveClasses = function () {
      $('.superbox-active .superbox-list').each(function () {
        $(this).removeClass('active');
      });
    };


    /*
     * IMPLEMENTATION
     */

    /*
     * Add superbox-active class to allow for CSS to take hold
     */
    this.addClass('superbox-active');

    /*
     * Add superbox-list class for easier CSS targeting
     */
    sbList.addClass('superbox-list');

    /*
     * Adjust superbox-show height and width based on window size
     */
    setSuperboxLayout();
    $(window).resize(function () {
      setSuperBoxHeight();
      setSuperboxLayout();
    });
    $(document).ajaxComplete(function () {
    //   $('.superbox-show').remove();
      $('.superbox-float').remove();
    });

    /*
     * Create final float
     */
    sbFloat.appendTo(this);

    /*
     * Preload image data when thumbnail is hovered over
     */
    sbList.on('mouseenter', function () {
      var img = new Image(),
        source = $(this).find('img').data('img');
      $(img).attr('src', source);
    });

    /*
     * Open/Close superbox-show based on click
     */
    sbList.unbind('click').on('click', function () {

      /*
       * Create superbox-show
       */
      createSuperboxShow($(this));

      /*
       * Apply user settings
       */
      useDefaults();
    });

    /*
     * Keep superbox-show after the proper row at all times
     */
    keepShowAfterLast();

    return this;
  };
})(jQuery);
